<div class="preloader" v-if="preloader"></div>
<div :class="'date-range-container form-group' + groupClass" v-else>
    <label v-if="label" :class="labelClassPrep" :for="attributes.id">{{label}}</label>
    <DatePicker
        is-range
        :mode="'date'"
        v-model="value"
        :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD',
        }"
    >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
            <span class="date-range-label">
                <span
                    class="date-range-label-start"
                    v-on="inputEvents.start"
                >{{ value.start || ''}}</span>
                <span
                    class="date-range-placeholder"
                    v-on="inputEvents.start"
                    v-if="!value.start"
                >{{ placeholder }}</span>
                <span
                    class="date-range-separator"
                    v-if="value.start"
                >{{' - '}}</span>
                <span
                    class="date-range-label-end"
                    v-on="inputEvents.end"
                >{{ value.end || '' }}</span>
            </span>
            <span
                class="date-range-label-reset"
                v-if="value.start"
                @click="value = {}"
            ></span>
        </template>
    </DatePicker>
</div>
