<template src="./templates/partner-contacts.html"></template>

<script>
    import Multiselect from '@vueform/multiselect';
    import '../../layouts/default/styles/partners.scss';

    import {
        getPartnerTypes,
        getCultivationTechnologyTree,
        findPartnersContacts,
        downloadPartnerContactsCsv,
        getClassifierItemTree, getPartnerOrdersFilter
    } from '@api/partners';

    import {
        getBranches
    } from '@api/layout';

    import Input from '@f/Input';
    import TreeSelect from '@f/TreeSelect';
    import FormCheckbox from '@f/Check';
    import Tree from '@c/Tree';
    import Grid from '@c/Grid';
    import DateRangePicker from '@c/DateRangePicker';
    import Button from '@f/Button';
    import Loader from '@c/Loader';
    import FormRadio from '@f/Radio/index';
    import {findModelsByCatalogCategory, findModelsCategory, GetCatalogCategoryTree} from '@api/product';
    import DropdownTree from './components/dropdown-tree';


    export default {
        name: 'PartnerContacts',
        components: {
            FormRadio,
            Button,
            Grid,
            Tree,
            Multiselect,
            Input,
            TreeSelect,
            FormCheckbox,
            DropdownTree,
            Loader,
            DateRangePicker,
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 100
            },
        },
        data() {
            return {
                loading: false,
                partnerTypes: [],
                cultivationTreeNodes: [],
                classifier: [],
                branches: [],
                partners: null,
                pageNumber: 0,
                allPages: 0,
                partnersCount: 0,
                partnerList: [],
                productCategoryTagsTree: [],
                modelsOptions: [],

                productTypesOptions: [],
                orderStatusOptions: [],
                closingStatusOptions: [],
                closeReasonsOptions: [],
                techCategoriesOptions: [],
                lastOrderStatusOptions: [],

                selectionValues: {
                    partnerTypes: [],
                    branchCodes: [],
                    emailExists: true,
                    cultivationTreeNodeIds: [],
                    limit: 100,
                    offset: 0,
                    minRating: 10,
                    maxGuestContacts: 2,
                    classifierItems: [],
                    classifiersUnionBy: 'and',
                    productCategoryTags: [],
                    selectedModels: [],
                    dateRange: {
                        start: null,
                        end: null,
                    },
                    orders: {
                        order_created: {
                            start: null,
                            end: null,
                        },
                        product_type: null,
                        order_status_id: [],
                        closing_status_id: [],
                        close_reason_id: [],
                        tech_categories_id: [],
                        tech_models: [],
                        without_closed: 0,
                    },
                },
            };
        },
        computed: {

        },
        watch: {
            'selectionValues.productCategoryTags': {
                immediate: true,
                handler(newVal) {
                    console.log('******', 'selectionValues.productCategoryTags: ', this.selectionValues.productCategoryTags);
                    this.loadModelsOptions(newVal);
                },
            },


            'selectionValues.orders.tech_categories_id': {
                immediate: true,
                handler(newVal) {
                    console.log('******', 'selectionValues.orders.tech_categories_id: ', this.selectionValues.orders.tech_categories_id);
                    this.loadOrderModelsOptions(newVal);
                },
            },


        },
        async created() {
            this.branches = await getBranches();
            const types = await getPartnerTypes();
            this.partnerTypes = Object.values(types);
            const tree = await getCultivationTechnologyTree(1);
            this.cultivationTreeNodes = tree.reduce((acc, item) => [...acc, ...item.children], []);
            this.classifier = await getClassifierItemTree();
            this.productCategoryTagsTree = await GetCatalogCategoryTree();

            await this.buildOrdersFilter();
        },
        methods: {
            async buildOrdersFilter() {
                const filter = await getPartnerOrdersFilter();
                this.productTypesOptions = filter?.product_types;
                this.orderStatusOptions = filter?.order_statuses;
                this.closingStatusOptions = filter?.order_close_statuses;
                this.closeReasonsOptions = filter?.order_close_reasons;
                this.techCategoriesOptions = filter?.product_category_tree;
            },
            async loadModelsOptions(catalogCategoryIds) {
                    this.modelsOptions = await findModelsByCatalogCategory({catalogCategoryIds});
            },
            async loadOrderModelsOptions(categoryIds) {
                    this.modelsOptions = await findModelsCategory({categoryIds});
            },
            async nextPage() {
                this.pageNumber++;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async prevPage() {
                this.pageNumber--;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async openPage(key) {
                this.pageNumber = key;
                await this.requestData(this.pageNumber * this.selectionValues.limit);
            },
            async requestData(offset) {
                this.loading = true;

                this.selectionValues.offset = offset;
                this.partnerList = await findPartnersContacts(this.selectionValues);
                this.partnersCount = await findPartnersContacts(this.selectionValues, true);
                this.allPages = Math.ceil(this.partnersCount / this.selectionValues.limit);

                this.loading = false;
            },
            async applyFilters() {
                this.pageNumber = 0;
                await this.requestData(0);
            },
            async downloadFile() {
                this.loading = true;

                const data = await downloadPartnerContactsCsv({
                    ...this.selectionValues,
                    offset: undefined,
                    limit: undefined
                });
                this.saveFile(data, 'temp.csv');

                this.loading = false;
            },
            saveFile(data, fileName) {
                const blob = new Blob([data], {type: 'application/csv'});

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            categoryTreeNormalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                };
            }
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.toggle-container {
    margin: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;

    .toggle-checkbox {
        display: none;
    }

    .toggle-label {
        display: inline-block;
        cursor: pointer;
        padding: 10px;
        background-color: #3498db;
        color: white;
        border-radius: 5px;
    }

    .toggle-content {
        display: none;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;

        .toggle-container {
            margin-top: 10px;
        }
    }

    .toggle-checkbox:checked {
        & ~ .toggle-content {
            display: block;
        }
    }
}

</style>
