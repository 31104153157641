<template src="./templates/date-range-picker.html"></template>
<script>

import {DatePicker} from 'v-calendar';

const default_values = {
    id: null,
    type: 'text',
    class: ''
};

export default {
    name: 'FormDateRangePicker',
    components: {
        DatePicker
    },
    props: {
        preloader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        modelValue: {
            type: Object,
            default() {
                return {
                    start: null,
                    end: null
                };
            },
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            }
        },
        label: {
            type: String,
            default() {
                return null;
            }
        },
        groupClass: {
            type: String,
            default() {
                return '';
            }
        },
        labelClass: {
            type: String,
            default() {
                return '';
            }
        },
        placeholder: {
            type: String,
            default() {
                return 'Выберите период...';
            }
        },
    },
    emits: ['update:modelValue'],
    computed: {
        labelClassPrep() {
            const cl = `${(this.labelClass) ? `${this.labelClass} ` : ''}`;
            return `${cl}h6`;
        },
        attributesPrep() {
            const attrs = {...default_values, ...this.attributes};

            if (!attrs.id) {
                attrs.id = `form-input-${Math.floor(Math.random() * 100000)}`;
            }

            return attrs;
        }
    },
    watch: {
        value: {
            handler(v, oldV) {
                this.$emit('update:modelValue', v);
            },
            deep: true,
        }
    },
    data() {
        return {
            value: this.modelValue,
        };
    }
};
</script>

<style scoped>
.date-range-container {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 40px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    outline: none;

    .date-range-label {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 14px;
        position: absolute;
        left: 0;
        top: 0;
        background: transparent;
    }
    .date-range-placeholder {
        color: #777;
    }
    .date-range-separator {
        margin: 0 10px;
    }
    .date-range-label-reset {
        position: absolute;
        right: 0;
        top: 50%;
        width: 36px;
        height: 36px;
        background: #fff;
        transform: translateY(-50%);

        &:before {
            position: absolute;
            top: 12px;
            left: 16px;
            content: " ";
            height: 12px;
            width: 2px;
            background-color: #999;
            transform: rotate(45deg);
        }
        &:after {
            position: absolute;
            top: 12px;
            left: 16px;
            content: " ";
            height: 12px;
            width: 2px;
            background-color: #999;
            transform: rotate(-45deg);
        }
    }
}

</style>
